import { useTranslation } from "react-i18next";

const About = () => {
    const { t } = useTranslation();
    return  (<div>
      <div className="page_content">
        <p>
          {t('about_brief1')}
        </p>
        <p>
          {t('about_brief2')}
        </p>
        <h2>{t('about_brief3')}</h2>
        <ul>
          <li>
            {t('about_integrity')}
            <ul><p>{t('about_integrity_detail')}</p></ul>
          </li>
          <li>
            {t('about_collaboration')}
            <ul><p>{t('about_collaboration_detail')}</p></ul>
          </li>
          <li>
            {t('about_innovation')}
            <ul><p>{t('about_innovation_detail')}</p></ul>
          </li>
        </ul>
      </div>
      <footer>{t('about_footer')}</footer>
    </div>);
  };
  
export default About;