import { useTranslation } from 'react-i18next';
const Team = () => {
  const { t } = useTranslation();
  return (<div>
    <div className="page_content">
      <p>
        {t('team_brief')}
      </p>
      <ul>
        <li>
          {t('team_ceo_founder')}
          <ul><p>{t('team_ceo_founder_detail')}</p></ul>
        </li>
        <li>
          {t('team_hod')}
          <ul><p>{t('team_hod_detail')}</p></ul>
        </li>
        <li>
          {t('team_operation')}
          <ul><p>{t('team_operation_detail')}</p></ul>
        </li>
      </ul>
    </div>
    <footer>{t('team_footer')}</footer>
  </div>);
};

export default Team;