import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Button from 'react-bootstrap/Button';
const Contact = () => {
  useEffect(() => emailjs.init("aFWraLuYnzgzvAx_A"), []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceId = "service_9h15wiw";
    const templateId = "template_pt52wtq";
    const templateparams = {
      from_name: `${name} :  ${email} , phone :${number}`,
      to_name: "contact@oraxustech.com",
      message,
    };
    try {
      try{
        await emailjs.send(serviceId, templateId, templateparams,"aFWraLuYnzgzvAx_A");
        reset();
      }catch(e){
        console.log("Error sending email ==> " , e);
      }
      
      
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const { t } = useTranslation();
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const reset = () => {
    setName("");
    setNumber("");
    setEmail("");
    setMessage("");
  }

  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setNumber(onlyDigits);
  };
  return (<div>
    <div className="page_content">
      <p>
        {t('contact_brief')}
      </p>
      <ul>
        <li>
          <b>{t('email')}</b> : customersupport@oraxustech.com
        </li>
        <li>
          <b>{t('phone')}</b> : (405) 826 5348
        </li>
        {/* <li>
            <b>{t('address')}</b> : 425 Ramsours Mill Dr, Holly Springs, NC 27540
          </li> */}
      </ul>
      <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Full Name</Form.Label>
        <Form.Control type="text" placeholder="Please enter your full name!" value={name} onInput={e=> setName(e.currentTarget.value)}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Phone</Form.Label>
        <Form.Control type="tel" maxLength="10"
        value={number}
        onInput={e=> setNumber(e.currentTarget.value)}
        onChange={(e) => checkInput(e)}
        placeholder="Please enter your contact number!" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com"  value={email} onInput={e=> setEmail(e.currentTarget.value)}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Your message</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Message"  value={message} onInput={e=> setMessage(e.currentTarget.value)}/>
      </Form.Group>
      <Button variant="primary" className="float-end" type="button" onClick={handleSubmit}>
        Submit
      </Button>
    </Form>
    </div>
    <footer>{t('project_footer')}</footer>
  </div >)
};

export default Contact;