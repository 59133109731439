import { Outlet, Link } from "react-router-dom";
import { useTranslation, withTranslation } from 'react-i18next';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../logo.png"
const Layout = () => {
    const { t } = useTranslation();
    const logoUrl = "./logo.jpeg";
  return (
    <>
    <Navbar bg="light" data-bs-theme="light" expand="lg" 
                className="container-fluid">
            <Navbar.Brand href="/">
                <img src={logo} 
                     width="50" height="50" 
                     alt="Oraxus" />
                <span className="brand-text">
                    Elevating Technology, Empowering You
                </span>
            </Navbar.Brand>
            <Navbar.Toggle 
                aria-controls="basic-navbar-nav" />
            <Navbar.Collapse 
                id="basic-navbar-nav">
                <Nav className="ms-auto"  variant="pills" defaultActiveKey="/">
                <Nav.Item><Nav.Link href="/services">{t('services')}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/projects">{t('projects')}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/team">{t('team')}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/about">{t('about')}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link href="/contact">{t('contact')}</Nav.Link></Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar> 
        <Outlet />
        </>     
  )
};

export default withTranslation()(Layout);