import { useTranslation } from 'react-i18next';
const Services = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="page_content">
                <p>
                    {t('service_brief')}
                </p>
                <ul>
                    <li>
                        {t('service_strategic_consulting')}
                        <ul>
                            <p>{t('service_strategic_consulting_detail')}</p>
                        </ul>
                    </li>
                    <li>
                        {t('service_technology_solutions')}
                        <ul>
                            <p>{t('service_technology_solutions_detail')}</p>
                        </ul>
                    </li>
                    <li>
                        {t('service_operational_effieciency')}
                        <ul>
                            <p>{t('service_operational_effieciency_detail')}</p>
                        </ul>
                    </li>
                    <li>
                        {t('service_it_staffice')}
                        <ul>
                            <p>{t('service_it_staffice_detail')}</p>
                        </ul>
                    </li>
                    <li>
                        {t('service_risk_compliance')}
                        <ul>
                            <p>{t('service_risk_compliance_detail')}</p>
                        </ul>
                    </li>
                    <li>
                        {t('service_project_management')}
                        <ul>
                            <p>{t('service_project_management_detail')}</p>
                        </ul>
                    </li>
                </ul>
            </div>
            <footer>{t('service_footer')}</footer>
        </>)
};

export default Services;