import { useTranslation } from 'react-i18next';
const Projects = () => {
    const { t} = useTranslation();
    return (<div>
      <div className="page_content">
        <p>
          {t('project_brief')}
        </p>
        <ul>
          <li>
            {t('project_sports')}
            <ul><p>{t('project_sports_detail')}</p></ul>
          </li>
        </ul>
      </div>
      <footer>{t('project_footer')}</footer>
    </div>);
  };
  
  export default Projects;