
import { useTranslation } from 'react-i18next';
const Home = (props) => {
    const { t } = useTranslation();

    return <div>
        <div className="page_content">
            <h1>{t('home_welcome')}</h1>
            <p>
                {t('home_breif')}
            </p>
            <ul>
                <li>
                    <a href='/services'>{t('home_explore_our_services')}</a>
                </li>
                <li>
                    <a href='/team'>{t('home_meet_our_team')}</a>
                </li>
                <li>
                    <a href='/projects'>{t('home_our_project')}</a>
                </li>
            </ul>
        </div>
        <footer>{t('home_footer')}</footer>
    </div>;
};

export default Home;